import React, { Component } from 'react';

import Styled from 'styled-components';

import BigForm from '../../primespot-ui/BigForm/components/bigForm';
import Page from '../../primespot-ui/BigForm/components/page';
import Textbox from '../../primespot-ui/BigForm/components/textbox';
import Textarea from '../../primespot-ui/BigForm/components/textarea';
import OptionGroup from '../../primespot-ui/BigForm/components/optionGroup';
import OptionButton from '../../primespot-ui/BigForm/components/optionButton';
import SuccessionList from '../../primespot-ui/BigForm/components/successionList';
import SuccessionItem from '../../primespot-ui/BigForm/components/successionItem';

// import logo from '../../images/rug-icon.png';
// import SVG from '../../primespot-ui/components/svg';

class BigFormPage extends Component {
  state = { activePage: 'four' };

  pageOrder = ['one', 'two', 'three', 'four'];

  nextPage = () => {
    const pageIndex = this.pageOrder.indexOf(this.state.activePage);
    if (pageIndex + 1 >= this.pageOrder.length) {
      this.setState({ activePage: this.pageOrder[0] });
    } else {
      this.setState({ activePage: this.pageOrder[pageIndex + 1] });
    }
  };

  previousPage = () => {
    const pageIndex = this.pageOrder.indexOf(this.state.activePage);
    if (pageIndex - 1 < 0) {
      this.setState({ activePage: this.pageOrder[this.pageOrder.length - 1] });
    } else {
      this.setState({ activePage: this.pageOrder[pageIndex - 1] });
    }
  };

  render() {
    return (
      <Wrapper>
        <BigForm
          pages={{
            one: <PageOne />,
            two: <PageTwo />,
            three: <PageThree continueText="Next" />,
            four: <PageFour />,
          }}
          activePage={this.state.activePage}
          finalPage="four"
          showControls={this.state.activePage !== 'finished'}
          initialValues={{
            fname: '',
            lname: '',
            favcolor: '',
            gender: '',
            favmovies: '',
            favband: '',
            favsong: '',
          }}
          onNextClick={obj => {
            this.nextPage();
          }}
          onBackClick={obj => {
            this.previousPage();
          }}
          onSubmit={data => {
            console.log('Finished!', data);
          }}
        />
      </Wrapper>
    );
  }
}

const Wrapper = Styled.div`
  padding: 24px;
  font-family: lato, sans-serif;
`;

const PageOne = props => {
  return (
    <Page {...props}>
      {({ values, onChange }) => (
        <div>
          <h2>Let's start with some personal information</h2>
          <Textbox
            label="First name"
            name="fname"
            value={values.fname}
            onChange={onChange}
          />
          <Textbox
            label="Last name"
            name="lname"
            value={values.lname}
            onChange={onChange}
          />
        </div>
      )}
    </Page>
  );
};

const PageTwo = props => {
  return (
    <Page {...props}>
      {({ values, onChange }) => (
        <div>
          <h2>Now on to some random junk</h2>
          <Textbox
            label="Favorite color"
            name="favcolor"
            value={values.favcolor}
            onChange={onChange}
          />
          <Textarea
            label="Favorite movies"
            name="favmovies"
            value={values.favmovies}
            onChange={onChange}
          />
        </div>
      )}
    </Page>
  );
};

const PageThree = props => {
  return (
    <Page {...props}>
      {({ values, onChange }) => (
        <div>
          <h2>Some more personal information here...</h2>
          <OptionGroup name="gender" value={values.gender} onChange={onChange}>
            <OptionButton value="male">Male</OptionButton>
            <OptionButton value="female">Female</OptionButton>
          </OptionGroup>
        </div>
      )}
    </Page>
  );
};

const PageFour = props => {
  return (
    <Page {...props}>
      {({ values, onChange }) => (
        <div>
          <SuccessionList>
            <SuccessionItem>
              {({ itemComplete }) => (
                <div onClick={itemComplete}>
                  <Textarea
                    label="Favorite band"
                    name="favband"
                    value={values.favband}
                    onChange={onChange}
                  />
                </div>
              )}
            </SuccessionItem>
            <SuccessionItem>
              {({ itemComplete }) => (
                <div onClick={itemComplete}>
                  <Textarea
                    label="Favorite song"
                    name="favsong"
                    value={values.favsong}
                    onChange={onChange}
                  />
                </div>
              )}
            </SuccessionItem>
            <SuccessionItem>
              {({ itemComplete }) => (
                <div onClick={itemComplete}>Item three</div>
              )}
            </SuccessionItem>
          </SuccessionList>
        </div>
      )}
    </Page>
  );
};

export default BigFormPage;
