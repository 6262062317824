import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';
import classnames from 'classnames';

const SuccessionItem = ({
  children,
  className,
  classes: passedClasses,
  itemComplete,
  ...rest
}) => {
  const classes = classnames(
    'primespot-ui__big-form__succession-item',
    className,
    passedClasses
  );
  return (
    <Wrapper className={classes} {...rest}>
      {children({ itemComplete })}
    </Wrapper>
  );
};

SuccessionItem.propTypes = {
  children: PropTypes.func,
};

const Wrapper = Styled.div`

`;

export default SuccessionItem;
